var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.data_table,"fields":_vm.fields,"creatable":"","noFilter":"","noPaginate":"","slugKey":"price_id","store":"helper.prices","resource":"/admin/goods/prices"},on:{"click-create":function($event){return _vm.$emit('update:showModalCreatePrice', true)}},scopedSlots:_vm._u([{key:"_",fn:function(ref){
var item = ref.item;
return [(item.price_rowspan > 0)?_c('td',{staticClass:"vertical-align-middle",attrs:{"rowspan":item.price_rowspan}}):_vm._e()]}},{key:"name",fn:function(ref){
var item = ref.item;
return [(item.price_rowspan > 0)?_c('td',{staticClass:"vertical-align-middle",attrs:{"rowspan":item.price_rowspan}},[_c('TMessageText',{attrs:{"value":item.name,"editable":"","removable":""},on:{"change":function($event){return _vm.onChangePriceName($event, item)},"click-remove":function($event){return _vm.onRemoveRow('prices', item.price_id)}}})],1):_vm._e()]}},{key:"conditions",fn:function(ref){
var item = ref.item;
return [(item.price_rowspan > 0)?_c('td',{staticClass:"vertical-align-middle",attrs:{"rowspan":item.price_rowspan}},[_c('div',{staticClass:"d-flex flex-column"},[_vm._l((item.conditions),function(condition){return _c('div',{key:condition.id},[(!['organization', 'type', 'user'].includes(condition.key))?_c('SMessagePriceCondition',{staticClass:"pt-3 mb-3",attrs:{"condition":condition,"editable":"","removable":"","noTranslate":"","groupConditionType":_vm.data_key},on:{"changed":_vm.reFetchPrices,"click-remove":function($event){return _vm.onRemoveRow('conditions', condition.id)}}}):_vm._e()],1)}),_c('div',{staticClass:"d-flex justify-content-end"},[_c('TButtonAdd',{on:{"click":function($event){return _vm.openModalAddPriceCondition(item)}}})],1)],2)]):_vm._e()]}},{key:"organization",fn:function(ref){
var item = ref.item;
return [(item.price_rowspan > 0)?_c('td',{staticClass:"vertical-align-middle",attrs:{"rowspan":item.price_rowspan}},[_c('SMessageOrganization',{attrs:{"id":item.organization && item.organization.value,"editable":""},on:{"change":function($event){return _vm.onChangeOrganization($event, item)}}})],1):_vm._e()]}},{key:"timeline_startdate",fn:function(ref){
var item = ref.item;
return [(item.timeline_rowspan > 0)?_c('td',{staticClass:"vertical-align-middle",attrs:{"rowspan":item.timeline_rowspan}},[_c('TMessageDateTime',{attrs:{"content":item.timeline_startdate,"editable":!!item.timeline_startdate,"creatable":!item.timeline_startdate,"removable":!!item.timeline_startdate},on:{"change":function($event){return _vm.onChangeTimelineStartDate($event, item)},"click-remove":function($event){return _vm.onRemoveRow('price_timelines', item.timeline_id)}}})],1):_vm._e()]}},{key:"range",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessagePriceRange',{attrs:{"range":item.range,"editable":!!(item.range && item.range.length),"removable":!!(item.range && item.range.length),"creatable":(!item.range || !item.range.length) && !!item.timeline_id,"unit":_vm.getPriceRangeUnit(item)},on:{"change":function($event){return _vm.onChangePriceRange($event, item)},"click-remove":function($event){return _vm.onRemoveRow('price_amounts', item.amount_id)},"submit-new-range":function($event){return _vm.onSubmitNewRange($event, item)}}})],1)]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.amount || 0,"suffix":_vm.getAmountUnit(item),"editable":!!item.amount_id},on:{"change":function($event){return _vm.onChangeAmount($event, item)}}})],1)]}}])}),_c('SModalPriceCondition',{ref:"modalPriceCondition",on:{"changed":_vm.reFetchPrices}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }